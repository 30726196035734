@font-face {
  font-family: "MetaHeadlinePro-Black";
  src: url("MetaHeadlinePro-Black.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "MetaOT-Light";
  src: url("MetaOT-Light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "HelveticaNeue";
  src: url("HelveticaNeue.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

$metablack: "MetaHeadlinePro-Black";
$metalight: "MetaOT-Light";
$helvetica: "HelveticaNeue";
