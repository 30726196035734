@import "../../assets/fonts/fonts.scss";

.loading {
  background: linear-gradient(to bottom, #000000 50%, #353535 100%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #fff;
  .background {
    position: absolute;
    height: 100vh;
    right: 0;
  }
  .basicLogo {
    top: 6%;
    height: 8%;
    position: absolute;
  }
  .smallTitleLight {
    font-size: 22px;
    margin-bottom: 20px;
    font-family: $metalight;
  }
  .mediumTitle {
    color: #be0100;
    font-size: 34px;
    text-transform: uppercase;
    font-family: $metablack;
    z-index: 90;
  }
  .bigTitle {
    font-size: 38px;
    text-transform: uppercase;
    font-family: $metablack;
  }
  .basicLogo,
  .smallTitleLight,
  .mediumTitle,
  .bigTitle {
    z-index: 3;
  }
}
