@import "../../assets/fonts/fonts.scss";
.errorScreen {
  background: linear-gradient(to bottom, #000000 50%, #353535 100%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .basicLogo {
    top: 19vh;
    height: 8%;
    position: absolute;
    z-index: 9;
  }
  .background {
    position: absolute;
    height: 100vh;
    right: 0;
  }
  .correlationBlock {
    position: absolute;
    bottom: 5vh;
    font-size: 8px;
    color: white;
    font-family: $helvetica;
    letter-spacing: 1px;
    text-align: center;
    .errorId {
      padding-bottom: 15px;
    }
  }
  .errorContent {
    z-index: 9;
    text-align: center;
    position: absolute;
    margin: auto;
    .smallTitle {
      font-size: 20px;
      line-height: 1em;
      text-transform: uppercase;
      font-family: $metablack;
      color: #fff;
      width: 90vw;
    }
  }
}
