@import "../../assets/fonts/fonts";

.marginTop {
  margin-top: 34px;
}
.measures {
  background-color: white;
  h2 {
    all: unset;
    display: block;
  }
  .heading {
    line-height: 68px;
    background-color: #f8f8f8;
    font-weight: bold;
    font-family: $metablack;
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
  }
}
