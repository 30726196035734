@keyframes ldio-rury1rispbo {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.ldio-rury1rispbo div {
  left: 47px;
  top: 24px;
  position: absolute;
  animation: ldio-rury1rispbo linear 0.9900990099009901s infinite;
  background: #ffffff;
  width: 6px;

  height: 12px;

  border-radius: 0px / 0px;

  transform-origin: 3px 26px;
}
.ldio-rury1rispbo div:nth-child(1) {
  transform: rotate(0deg);

  animation-delay: -0.9075907590759075s;

  background: #ffffff;
}
.ldio-rury1rispbo div:nth-child(2) {
  transform: rotate(30deg);

  animation-delay: -0.825082508250825s;

  background: #ffffff;
}
.ldio-rury1rispbo div:nth-child(3) {
  transform: rotate(60deg);

  animation-delay: -0.7425742574257426s;

  background: #ffffff;
}
.ldio-rury1rispbo div:nth-child(4) {
  transform: rotate(90deg);

  animation-delay: -0.6600660066006601s;

  background: #ffffff;
}
.ldio-rury1rispbo div:nth-child(5) {
  transform: rotate(120deg);

  animation-delay: -0.5775577557755776s;

  background: #ffffff;
}
.ldio-rury1rispbo div:nth-child(6) {
  transform: rotate(150deg);

  animation-delay: -0.495049504950495s;

  background: #ffffff;
}
.ldio-rury1rispbo div:nth-child(7) {
  transform: rotate(180deg);

  animation-delay: -0.4125412541254125s;

  background: #ffffff;
}
.ldio-rury1rispbo div:nth-child(8) {
  transform: rotate(210deg);

  animation-delay: -0.33003300330033003s;

  background: #ffffff;
}
.ldio-rury1rispbo div:nth-child(9) {
  transform: rotate(240deg);

  animation-delay: -0.2475247524752475s;

  background: #ffffff;
}
.ldio-rury1rispbo div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.16501650165016502s;
  background: #ffffff;
}
.ldio-rury1rispbo div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.08250825082508251s;
  background: #ffffff;
}
.ldio-rury1rispbo div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
  background: #ffffff;
}

.loadingio-spinner-spinner-d79520ez52s {
  width: 68px;
  height: 68px;
  display: inline-block;
  overflow: hidden;
  background: none;
  position: absolute;
  bottom: 50px;
}

.ldio-rury1rispbo {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.68);
  backface-visibility: hidden;
  transform-origin: 0 0;
}

.ldio-rury1rispbo div {
  box-sizing: content-box;
}
