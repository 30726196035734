body {
  margin: 0;
  background-color: #f8f8f8;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.rtl {
  direction: rtl;
}

.scrollLock {
  position: fixed;
}

html,
body {
  min-width: 260px;
  max-width: 100%;
  position: relative;
  overflow-x: hidden;

  button {
    all: unset;
  }
}
