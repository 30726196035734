@import "../../assets/fonts/fonts";
.textWrapper {
  position: relative;
  top: 6%;
  transform: translateY(-50%);
  font-family: $helvetica;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.05em;
  overflow: scroll;

  div:first-child {
    margin-top: 24px;
  }
  .languageItem {
    color: #000;
    padding-bottom: 24px;
  }
  .selectedLanguage {
    color: #be0100;
    margin-bottom: 24px;
  }
}
