@import "../../assets/fonts/fonts";
.specificationsBlackWrapper {
  background-color: #212121;
  color: #fff;
  h2 {
    all: unset;
    display: block;
  }
  .xsTitle {
    font-weight: bold;
    font-family: $metablack;
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    padding-top: 30px;
  }
  .accordionItem {
    border-bottom: 1px solid #575757;
    &:nth-child(2) {
      border: none;
    }
  }
  .accordionTitle {
    text-transform: none;
    &::after {
      border-color: #fff transparent transparent;
    }
    span {
      padding-right: 18px;
      line-height: normal;
    }
    span > img {
      height: 21px;
      width: 21px;
    }
  }

  .accordionTitleRTL {
    span {
      padding-right: 0px;
    }
  }
  .accordionContent {
    padding: 0 5vw;
    .description {
      padding-left: 40px;
      padding-right: 20px;
      font-size: 16px;
    }
  }
}

.specificationsBlackWrapperRTL .accordionContent .description {
  padding-right: 40px;
  padding-left: 20px;
}

.specificationsLightWrapper {
  background-color: #fff;
  color: #212121;
  .xsTitle {
    line-height: 68px;
    padding-top: 0;
    background-color: #f8f8f8;
  }
  .accordionItem {
    border-bottom: 1px solid #e0e0e0;
  }
  .accordionTitle {
    &::after {
      border-color: #212121 transparent transparent;
    }
  }
}
