@import "../../assets/fonts/fonts";
.mention {
  padding-top: 30px;
  padding-bottom: 34px;
  color: #007474;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.05em;
  font-family: $helvetica;
  & > img {
    height: 16px;
    width: 24px;
    padding-right: 10px;
  }
  &.border {
    border-bottom: 1px solid #e0e0e0;
  }
  .smallText {
    padding-left: 35px;
    color: #000;
    font-weight: 200;
  }
}
.mentionRTL {
  & > img {
    padding-left: 10px;
    padding-right: unset;
  }
  .smallText {
    padding-right: 35px;
    padding-left: unset;
  }
}
