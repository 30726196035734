@import "../../assets/fonts/fonts";

.servicesWrapper {
  background-color: white;
  h2 {
    all: unset;
    display: block;
  }
  .header,
  .headerLight {
    line-height: 68px;
    font-weight: bold;
    font-family: "MetaHeadlinePro-Black";
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
  }
  .headerLight {
    background-color: #f8f8f8;
  }
  .header {
    background-color: #f8f8f8;
  }
  .heading {
    font-size: 14px;
    letter-spacing: 0.05em;
    font-family: $helvetica;
    font-weight: 1000;
    padding-bottom: 10px;
    &:first-of-type {
      padding-top: 14px;
    }
  }
  .description {
    padding-bottom: 20px;
    &:last-of-type {
      padding-bottom: 30px;
    }
    .exlcusions {
      margin-bottom: 5px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  .warrantyLegalNotice {
    margin-bottom: 34px;
  }
}
