@import "../../assets/fonts/fonts.scss";
.associateScreen {
  background: linear-gradient(to bottom, #000000 50%, #353535 100%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #fff;
  .background {
    height: 100vh;
    width: 100vw;
  }
  .basicLogo {
    top: 6%;
    height: 8%;
    position: absolute;
  }
  .bigTitle {
    font-size: 38px;
    text-transform: uppercase;
    font-family: $metablack;
  }
  .basicLogo,
  .bigTitle {
    z-index: 1;
  }
  .textWrapperBody {
    text-align: center;
    position: absolute;
    color: white;
    left: 0;
    right: 0;
    top: 21vh;
    z-index: 3;
    width: 80vw;
    margin: auto;

    .bigTitle {
      font-size: 18px;
      line-height: 1;
      font-family: $metablack;
      letter-spacing: 0.05em;
      margin-bottom: 20px;
    }

    .smallTitle {
      margin: 0 auto 50px;
      font-size: 16px;
      font-family: $metalight;
      line-height: 1.2;
    }

    .sessionMsg {
      margin: 0 auto 50px;
      font-size: 20px;
      font-family: $helvetica;
      line-height: 1.2;
      font-weight: bold;
      color: #be0100;
    }

    .numbers {
      font-size: 30px;
      font-family: $helvetica;
      margin: 0 auto 50px;

      .digit {
        padding: 3px 8px;
        background: #212121;
        margin: 0px 2px;
        border-radius: 10px;
        font-size: 30px;
        font-weight: 600;
      }
    }

    @media (max-width: 300px) {
      .smallTitle,
      .sessionMsg,
      .numbers {
        margin: 0 auto 25px;
      }
    }

    @media (min-width: 301px) and (max-height: 700px) {
      .smallTitle,
      .sessionMsg,
      .numbers {
        margin: 0 auto 36px;
      }
    }
  }
}
