@import "../../assets/fonts/fonts";

.timer-wrapper {
  display: flex;
  justify-content: center;

  .timer {
    font-family: $helvetica;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #be0100;
    font-weight: bold;
  }

  .timerExpier {
    font-size: 40px;
    font-weight: bold;
  }

  .text {
    color: #be0100;
    line-height: 0.7;
    font-size: 10px;
  }

  .value {
    font-size: 20px;
  }
}
