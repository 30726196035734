@import "../../assets/fonts/fonts";
.productDetails {
  padding-left: 5vw;
  padding-right: 5vw;
  padding-bottom: 22px;
  background-color: #212121;
  color: #fff;
  .imagesWrapper {
    padding: 0 22px;
    .watchidLogo {
      height: 40px;
    }
    .watchidLogoLight {
      height: 47px;
      margin-left: -7px;
    }
    .basicLogo {
      width: 32.6%;
      display: block;
      margin: 0 auto;
      padding-bottom: 40px;
    }
    .specialEdition {
      display: flex;
      padding: 11px 10px 5px 10px;
      background: #404040;

      img {
        align-self: center;
        width: 20px;
        padding: 5px 10px;
      }
      div {
        color: white;
        align-self: center;
        font-size: 12px;
        line-height: 1.5em;
        font-family: "HelveticaNeue";
        font-weight: 200;
        span {
          display: block;
        }
      }
    }
    .specialEditionLight {
      background: #212121;
    }
  }
  .detailsWrapper {
    text-align: center;
    padding-top: 40px;
    .currentText {
      line-height: 1.4em;
      font-family: $helvetica;
      font-size: 14px;
      letter-spacing: 0.05em;
      font-weight: 200;
    }
    .underNote {
      font-family: $helvetica;
      font-size: 14px;
      letter-spacing: 0.05em;
      line-height: 1.5;
      padding-bottom: 12px;
      padding-top: 12px;
    }
    .xsTitle {
      padding-top: 40px;
      font-family: $metablack;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold;
      span {
        display: block;
      }
    }
    .smallTitle {
      font-family: $metablack;
      font-size: 20px;
      text-transform: uppercase;
      padding-bottom: 15px;
      h2 {
        all: unset;
      }
    }
    .masterLogo {
      width: 84px;
      margin: 0 auto;
      display: block;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
  .confederation {
    width: 100%;
  }
}

.productDetailsLight {
  color: #212121;
  background-color: white;
}
