@import "../../assets/fonts/fonts";
.demo {
  line-height: 17px;
  margin: 0 auto;
  background: red;
  color: white;
  font-size: 13px;
  position: fixed;
  width: 100%;
  text-align: center;
  font-family: $helvetica;
}
