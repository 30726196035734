@import "../../assets/fonts/fonts";

.accordionTitle {
  padding: 20px 9vw 20px 5vw;
  display: flex;
  align-items: center;
  font-family: $helvetica;
  font-weight: 1000;
  font-size: 14px;
  letter-spacing: 0.05em;
  width: 100%;
  h3 {
    all: unset;
    display: block;
  }
  & > img {
    width: 30px;
    padding-right: 10px;
    &.swissFlag {
      height: 21px;
      width: 21px;
      padding-right: 17px;
    }
  }

  &::after {
    content: "";
    position: absolute;
    right: 20px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8.6px 5px 0px;
    border-color: rgb(0, 0, 0) transparent transparent;
  }

  &.open {
    &::after {
      transform: rotate(180deg);
    }
  }
}

.accordionTitleRTL {
  &::after {
    right: unset;
    left: 20px;
  }

  & > img,
  & span > img {
    padding-right: unset;
    padding-left: 10px;
    &.swissFlag {
      padding-left: 17px;
      padding-right: unset;
    }
  }
}

.accordionItem {
  border-bottom: 1px solid #e0e0e0;
  overflow: hidden;
  margin-top: -10px;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 999px;
  &.collapsed {
    margin-top: 0;
    max-height: 0;
    transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
  }
  .accordionContent {
    padding: 0 60px;
    font-family: $helvetica;
    font-size: 14px;
    font-weight: 200;
    line-height: 1.6em;
    .specificationsBlack {
      padding-bottom: 34px;
      .currentText {
        display: flex;
        align-items: center;
        padding-bottom: 10px;
        font-size: 14px;
        font-weight: 600;
        span {
          line-height: normal;
          padding-right: 18px;
        }
        span > img {
          height: 21px;
          width: 21px;
        }
      }
    }
  }
}
